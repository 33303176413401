import { Page } from "components/Page";
import React from "react";

const ContactPage = () => {
  return (
    <Page>
      <section className="container mx-auto">
        <div className="md:ml-24 mt-24">
          <h1>CONTACT</h1>
          <div className="flex flex-col md:flex-row gap-8 md:gap-48 mt-10">
            <div>
              <h3>VISIT US</h3>
              <div className="mt-8">
                Blaby Civic Centre <br />
                22-24 Leicester Road <br />
                Blaby <br />
                Leicester <br />
                LE8 4GQ
              </div>
            </div>
            <div>
              <h3>SAY HELLO</h3>
              <div className="mt-8">
                0116 224 1344
                <br />
                <a href="mailto:mail@rockstarpromotions.co.uk">
                  mail@rockstarpromotions.co.uk
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default ContactPage;
